.jquery-lightbox-move, .jquery-lightbox.jquery-lightbox-mode-html, .jquery-lightbox-background {
  max-width: 100%;
  max-height: 100%; }

.jquery-lightbox-move .jquery-lightbox-html div * {
  webkit-box-sizing: border-box !important;
  -moz-box-sizing: border-box !important;
  box-sizing: border-box !important; }

.auth-btn-google .btn-site {
  background: #dd4b39 !important;
  color: #fff !important; }

.auth-btn-google:hover .btn-site {
  background: #ff6350 !important;
  color: #fff !important; }

.auth-btn-facebook .btn-site {
  background: #3d5a98 !important;
  color: #fff !important; }

.auth-btn-facebook:hover .btn-site {
  background: #5683e2 !important;
  color: #fff !important; }

.auth-btn-twitter .btn-site {
  background: #28a9e0 !important;
  color: #fff !important; }

.auth-btn-twitter:hover .btn-site {
  background: #6ed4ff !important;
  color: #fff !important; }

.auth-btn-github .btn-site {
  background: #2b414e !important;
  color: #fff !important; }

.auth-btn-github:hover .btn-site {
  background: #436e88 !important;
  color: #fff !important; }

.admin-site-2 {
  /* Select2 Plugin */
  /* Dates Plugins*/
  /* PDF Doc */
  /* Froala View */
  /* Parent Node */
  /* Loader */
  /* Form Control */
  /* Subadmin Image Container */
  /* Flex */
  /* Tooltip */
  /* Mapa de Admin */
  /* Extra Field Correction */
  /* Filtro */
  /* Data Table */
  /* New Admin */
  /* File Upload */
  /* Admin */
  /* Store: Sale */ }
  .admin-site-2 .select2-container .select2-selection--single {
    height: auto;
    border: 1px solid #ccc; }
    .admin-site-2 .select2-container .select2-selection--single .select2-selection__rendered {
      line-height: initial;
      padding: 8px;
      padding-left: 12px;
      text-align: left;
      min-height: 32px; }
    .admin-site-2 .select2-container .select2-selection--single .select2-selection__clear {
      display: none; }
  .admin-site-2 .select2-container .select2-results > .select2-results__options {
    max-height: 200px;
    overflow-y: auto; }
  .admin-site-2 .datepicker, .admin-site-2 .timepicker, .admin-site-2 .pick-control {
    cursor: pointer; }
  .admin-site-2 .picker__header select {
    padding: 4px; }
  .admin-site-2 .picker__holder {
    margin-left: 20px;
    min-width: 400px; }
  .admin-site-2 .content-wrap.pdf-wrap {
    width: 100%;
    margin: 0;
    padding: 0;
    position: relative;
    display: block;
    padding-top: 10px; }
    .admin-site-2 .content-wrap.pdf-wrap h1 {
      margin-bottom: 30px;
      font-size: 28px; }
    .admin-site-2 .content-wrap.pdf-wrap h2 {
      font-size: 22px; }
    .admin-site-2 .content-wrap.pdf-wrap h3 {
      font-size: 18px; }
    .admin-site-2 .content-wrap.pdf-wrap h4 {
      font-size: 16px; }
    .admin-site-2 .content-wrap.pdf-wrap .content-wrap-2 {
      padding-bottom: 50px; }
    .admin-site-2 .content-wrap.pdf-wrap .tooltip-mini {
      color: #333333;
      font-size: 12px;
      line-height: 17px;
      font-weight: normal;
      padding: 10px;
      border: 1px dashed #bbb;
      text-align: justify; }
    .admin-site-2 .content-wrap.pdf-wrap .flex {
      position: relative;
      display: block !important;
      flex-wrap: none; }
  .admin-site-2 .fr-view {
    color: #333333; }
    .admin-site-2 .fr-view table {
      width: 100%; }
      .admin-site-2 .fr-view table td {
        padding: 10px;
        text-align: center; }
    .admin-site-2 .fr-view ol li {
      list-style: upper-alpha; }
  .admin-site-2 .parent_node {
    padding: 10px;
    background: #f3f3f3;
    border: 1px dashed #999;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    border-radius: 10px; }
    .admin-site-2 .parent_node h4 {
      margin-top: 10px; }
    .admin-site-2 .parent_node h4:hover {
      cursor: pointer; }
    .admin-site-2 .parent_node .accordion-content {
      padding: 10px;
      border-top: 1px dashed #999; }
  .admin-site-2 .m-page-loader.m-page-loader--base {
    background: #ffffff; }
  .admin-site-2 .control-label {
    font-size: 1.2rem;
    margin-bottom: 5px;
    text-align: left;
    line-height: 53px;
    padding-top: 0;
    padding-bottom: 0; }
  .admin-site-2 label.mt-checkbox, .admin-site-2 label.mt-radio {
    margin-right: 10px;
    font-weight: 400;
    padding-top: 0; }
  .admin-site-2 input.form-control, .admin-site-2 select.form-control {
    height: auto;
    width: 100%; }
  .admin-site-2 .table .file_container {
    width: 48%;
    display: inline-block; }
  .admin-site-2 .table input.fileupload {
    width: 50%;
    display: inline-block; }
  .admin-site-2 .flex {
    position: relative;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-wrap: wrap; }
  .admin-site-2 .flex-item {
    position: relative; }
  .admin-site-2 .tooltipstered {
    padding-left: 10px; }
  .admin-site-2 .tooltip-custom {
    color: #fff;
    font-size: 16px;
    line-height: 24px;
    max-width: 700px;
    background: #136957;
    padding: 10px;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    border-radius: 10px; }
  .admin-site-2 .pac-container.pac-logo {
    z-index: 999999; }
  .admin-site-2 .map-box {
    height: 400px; }
    .admin-site-2 .map-box ul.legend-list {
      list-style: none;
      margin-right: 25px;
      margin-top: 20px; }
      .admin-site-2 .map-box ul.legend-list li {
        padding-bottom: 10px; }
        .admin-site-2 .map-box ul.legend-list li img {
          margin-bottom: 5px; }
    .admin-site-2 .map-box .map-search-box {
      padding: 5px 10px 5px 12px;
      margin-top: 10px;
      margin-left: 10px;
      min-width: 200px;
      width: 25%; }
    .admin-site-2 .map-box #pac-input {
      padding: 5px 10px 5px 12px;
      margin-top: 10px;
      margin-left: 10px; }
    .admin-site-2 .map-box #legend {
      z-index: 0;
      position: absolute;
      right: 0px;
      background: rgba(255, 255, 255, 0.59); }
    .admin-site-2 .map-box .btn:hover {
      border: 3px solid #136957;
      background: #fff;
      color: #fff;
      -moz-transition: all 0.5s ease;
      -o-transition: all 0.5s ease;
      -webkit-transition: all 0.5s ease;
      transition: all 0.5s ease;
      color: #136957; }
    .admin-site-2 .map-box a.active .btn {
      background: #136957;
      color: #fff; }
  .admin-site-2 .extra_table_field label {
    display: none; }
  .admin-site-2 ul.table-list {
    margin-top: 20px;
    margin-left: 0;
    padding-left: 0; }
    .admin-site-2 ul.table-list li {
      list-style: none;
      padding: 15px;
      border-top: 1px dashed #bbb; }
  .admin-site-2 .filter {
    margin-bottom: 20px; }
  .admin-site-2 table.dataTable thead th, .admin-site-2 table.dataTable thead td {
    border-bottom: 1px solid #bbb; }
  .admin-site-2 .dataTables_wrapper input, .admin-site-2 .dataTables_wrapper select {
    display: initial !important;
    width: auto !important; }
  .admin-site-2 .mt-checkbox-inline, .admin-site-2 .mt-radio-inline {
    padding: 0; }
  .admin-site-2 .title .control-label {
    font-weight: bold;
    background: #eee;
    width: 100%;
    padding: 5px 20px;
    margin-top: 20px; }
  .admin-site-2 .mt-radio-inline label.mt-radio, .admin-site-2 .mt-checkbox-inline label.mt-checkbox {
    color: #777; }
  .admin-site-2 label.mt-checkbox, .admin-site-2 label.mt-radio {
    margin-top: 0;
    line-height: 1.85em; }
  .admin-site-2 .progress_bar {
    margin-top: 5px;
    display: none;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    background: #eee;
    border: 1px solid #ccc; }
    .admin-site-2 .progress_bar .bar {
      height: 18px;
      background: #136957;
      -moz-border-radius: 3px;
      -webkit-border-radius: 3px;
      border-radius: 3px; }
  .admin-site-2 .file_container .file_limitations p {
    font-size: 13px;
    color: #999;
    margin-bottom: 0;
    margin-top: 0; }
  .admin-site-2 .file_container div.image_thumb {
    position: relative;
    padding: 5px;
    border: 1px solid #ccc;
    display: inline-block;
    margin-right: 5px;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: #eee; }
    .admin-site-2 .file_container div.image_thumb img {
      -moz-border-radius: 5px;
      -webkit-border-radius: 5px;
      border-radius: 5px;
      border: 1px solid #ccc; }
    .admin-site-2 .file_container div.image_thumb a.delete_temp {
      display: block;
      width: 20px;
      height: 20px;
      position: absolute;
      top: 5px;
      right: 5px;
      z-index: 99;
      background: #eee;
      border-bottom: 1px solid #ccc;
      border-left: 1px solid #ccc;
      text-align: center;
      line-height: 20px;
      border-top-right-radius: 5px;
      border-bottom-left-radius: 5px; }
  .admin-site-2 .file_container div.file_thumb {
    margin-bottom: 3px;
    border-top: 1px dashed #ccc; }
    .admin-site-2 .file_container div.file_thumb a.delete_temp {
      float: right; }
  .admin-site-2 .section-admin {
    border-top: 5px dashed #ccc;
    padding-top: 20px;
    margin-top: 12px; }
  .admin-site-2 .admin-table, .admin-site-2 .table {
    text-align: center;
    width: 100%;
    background: #fff;
    margin-top: 10px;
    margin-bottom: 0; }
    .admin-site-2 .admin-table .edit, .admin-site-2 .admin-table .delete, .admin-site-2 .admin-table .restore, .admin-site-2 .table .edit, .admin-site-2 .table .delete, .admin-site-2 .table .restore {
      width: 90px;
      overflow: hidden; }
    .admin-site-2 .admin-table tbody .edit, .admin-site-2 .admin-table tbody .delete, .admin-site-2 .admin-table tbody .restore, .admin-site-2 .table tbody .edit, .admin-site-2 .table tbody .delete, .admin-site-2 .table tbody .restore {
      padding: 0; }
      .admin-site-2 .admin-table tbody .edit a, .admin-site-2 .admin-table tbody .delete a, .admin-site-2 .admin-table tbody .restore a, .admin-site-2 .table tbody .edit a, .admin-site-2 .table tbody .delete a, .admin-site-2 .table tbody .restore a {
        padding: 10px;
        width: 100%;
        display: block; }
    .admin-site-2 .admin-table tbody .edit, .admin-site-2 .table tbody .edit {
      background: #dff0d8;
      border: 1px solid #d6e9c6;
      color: #3c763d; }
      .admin-site-2 .admin-table tbody .edit a, .admin-site-2 .table tbody .edit a {
        color: #3c763d; }
      .admin-site-2 .admin-table tbody .edit a:hover, .admin-site-2 .table tbody .edit a:hover {
        color: #326533; }
    .admin-site-2 .admin-table tbody .delete, .admin-site-2 .table tbody .delete {
      background: #f2dede;
      border: 1px solid #ebccd1;
      color: #a94442; }
      .admin-site-2 .admin-table tbody .delete a, .admin-site-2 .table tbody .delete a {
        color: #a94442; }
      .admin-site-2 .admin-table tbody .delete a:hover, .admin-site-2 .table tbody .delete a:hover {
        color: #772625; }
    .admin-site-2 .admin-table tbody .restore, .admin-site-2 .table tbody .restore {
      background: #deeff2;
      border: 1px solid #bce3ea;
      color: #11729c; }
      .admin-site-2 .admin-table tbody .restore a, .admin-site-2 .table tbody .restore a {
        color: #11729c; }
      .admin-site-2 .admin-table tbody .restore a:hover, .admin-site-2 .table tbody .restore a:hover {
        color: #3c95bb; }
    .admin-site-2 .admin-table .title, .admin-site-2 .table .title {
      font-weight: bold;
      background: #ECECEC; }
  .admin-site-2 .admin-table.section-buttons {
    width: 100%;
    max-width: 400px;
    margin: auto;
    border: 1px solid #ccc; }
  .admin-site-2 .admin-table.admin-menu td {
    border-bottom: 1px solid #ddd; }
  .admin-site-2 .admin-table.admin-menu .child1 {
    margin-left: 20px; }
  .admin-site-2 .admin-table.admin-menu .child2 {
    margin-left: 50px; }
  .admin-site-2 .admin-table.admin-menu .padding {
    background: #fff;
    color: #fff;
    width: 5px;
    border-top: none;
    border-bottom: none; }
  .admin-site-2 .admin-table.admin-menu .borde {
    border-left: 5px solid #f9f9f9; }
  .admin-site-2 .child {
    margin-top: 50px; }
  .admin-site-2 .form-horizontal label {
    margin-top: 5px; }
  .admin-site-2 .form-horizontal .control-label {
    text-align: left; }
  .admin-site-2 .btn-site {
    margin-top: 11px;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
    background-color: #136957;
    color: #fff; }
  .admin-site-2 .btn-site:hover {
    background-color: #4c99bf;
    color: #fff; }
  .admin-site-2 .field-message {
    font-style: italic;
    color: #999; }
  .admin-site-2 #create-sale label {
    font-size: 20px; }
  .admin-site-2 #create-sale input.form-control, .admin-site-2 #create-sale select.form-control, .admin-site-2 #create-sale .select2-container .select2-selection--single .select2-selection__rendered {
    font-size: 20px;
    padding: 10px 20px !important; }
  .admin-site-2 #create-sale .btn {
    font-size: 20px;
    padding: 10px 20px !important;
    width: 100%; }
  .admin-site-2 #create-sale .mt-checkbox > span, .admin-site-2 #create-sale .mt-radio > span {
    top: 9px; }
  .admin-site-2 #create-sale .total {
    font-size: 24px;
    font-weight: bold;
    color: #136957; }
  .admin-site-2 #create-sale #products #amount.form-control[readonly], .admin-site-2 #create-sale #paid_amount.form-control[readonly] {
    background: #136957;
    color: #fff; }
  .admin-site-2 #create-sale .icon {
    text-align: right; }
    .admin-site-2 #create-sale .icon i {
      font-size: 60px;
      line-height: 90px;
      color: #136957; }
