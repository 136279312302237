/*========================================================
        DARK LAYOUT
=========================================================*/
#dashboard-analytics .img-left
{
    position: absolute;
    top: 0;
    left: 0; 

    width: 200px;
}

#dashboard-analytics .img-right
{
    position: absolute;
    top: 0;
    right: 0; 

    width: 175px;
}

#dashboard-analytics .bg-analytics
{
    background: -webkit-linear-gradient(332deg, #7367f0, rgba(115, 103, 240, .7));
    background:    -moz-linear-gradient(332deg, #7367f0, rgba(115, 103, 240, .7));
    background:      -o-linear-gradient(332deg, #7367f0, rgba(115, 103, 240, .7));
    background:         linear-gradient(118deg, #7367f0, rgba(115, 103, 240, .7));
}
#dashboard-analytics .bg-analytics .avatar
{
    margin-bottom: 2rem;
}

#dashboard-analytics table tr th:first-child,
#dashboard-analytics table tr td:first-child
{
    padding-left: 2rem;
}

#dashboard-analytics table tr th:last-child,
#dashboard-analytics table tr td:last-child
{
    padding-right: 2rem;
}

#dashboard-analytics table td
{
    padding: .75rem;
}

@media only screen and (max-width: 576px)
{
    #dashboard-analytics .img-left,
    #dashboard-analytics .img-right
    {
        width: 140px;
    }
}
