/*========================================================
        DARK LAYOUT
=========================================================*/
.ecommerce-application .content-body
{
    position: relative;
}

.ecommerce-application .shop-content-overlay
{
    position: absolute;
    z-index: 0;
    top: 4.2rem;

    width: 100%;
    height: 100%;

    opacity: 0; 
    background: rgba(0, 0, 0, .2);
}
.ecommerce-application .shop-content-overlay.show
{
    z-index: 1; 

    opacity: 1;
}

.ecommerce-application .sidebar-shop .sidebar-close-icon
{
    font-size: 1.25rem;

    position: absolute;
    z-index: 5;
    top: .25rem;
    right: .25rem;

    cursor: pointer;
}

.ecommerce-application .sidebar-shop .filter-heading
{
    font-weight: 700;

    position: relative;
    top: -7px; 

    color: #626262;
}

.ecommerce-application .sidebar-shop .vs-radio-con input:checked ~ span
{
    color: #7367f0;
}

.ecommerce-application .sidebar-shop .filter-title
{
    font-weight: 700;
}

.ecommerce-application .sidebar-shop .range-slider.noUi-horizontal
{
    height: .285rem;
}
.ecommerce-application .sidebar-shop .range-slider.noUi-horizontal .noUi-base
{
    height: .285rem;
}
.ecommerce-application .sidebar-shop .range-slider.noUi-horizontal .noUi-handle
{
    line-height: 1.15;

    top: -.428rem;
    right: -1.071rem !important;

    width: 16px;
    height: 16px;

    cursor: pointer; 

    border-width: .142rem;
}
.ecommerce-application .sidebar-shop .range-slider.noUi-horizontal .noUi-handle .noUi-tooltip
{
    font-size: .75rem;

    padding: .142rem .357rem;

    -webkit-transform: translate(-50%, -50%);
       -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
         -o-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); 

    opacity: 0;
    color: #fff;
    border: none;
    border-radius: .357rem;
    background-color: #7367f0;
}
.ecommerce-application .sidebar-shop .range-slider.noUi-horizontal .noUi-handle .noUi-tooltip:before
{
    content: '$ ';
}
.ecommerce-application .sidebar-shop .range-slider.noUi-horizontal .noUi-handle:hover
{
    -webkit-transform: scale(1.2);
       -moz-transform: scale(1.2);
        -ms-transform: scale(1.2);
         -o-transform: scale(1.2);
            transform: scale(1.2);
}
.ecommerce-application .sidebar-shop .range-slider.noUi-horizontal .noUi-handle:active
{
    -webkit-transition: .25s ease;
       -moz-transition: .25s ease;
         -o-transition: .25s ease;
            transition: .25s ease; 
    -webkit-transform: scale(1);
       -moz-transform: scale(1);
        -ms-transform: scale(1);
         -o-transform: scale(1);
            transform: scale(1);

    border-width: .5rem;
}
.ecommerce-application .sidebar-shop .range-slider.noUi-horizontal .noUi-handle:active .noUi-tooltip
{
    opacity: 1;
}

.ecommerce-application .sidebar-shop .ratings-list .ratings-list-item
{
    font-size: 1.5rem;

    cursor: pointer;
}

.ecommerce-application .sidebar-shop .ratings-list ~ .stars-received
{
    margin-top: .35rem;
}

.ecommerce-application .sidebar-shop
{
    z-index: 4; 

    width: 260px;
    margin-top: 2rem;
}

.ecommerce-application .ecommerce-header-items
{
    display: -webkit-box;
    display: -webkit-flex;
    display:    -moz-box;
    display: -ms-flexbox;
    display:         flex;

    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
       -moz-box-pack: justify;
    -ms-flex-pack: justify;
            justify-content: space-between;
}
.ecommerce-application .ecommerce-header-items .view-options .view-btn-option
{
    display: inline-block;
}
.ecommerce-application .ecommerce-header-items .view-options .view-btn-option .btn.view-btn
{
    margin-right: 10px;
    padding: .5rem;

    color: #626262;
    background-color: #fff;
    -webkit-box-shadow: 0 2px 8px 0 rgba(0, 0, 0, .14) !important;
            box-shadow: 0 2px 8px 0 rgba(0, 0, 0, .14) !important;
}
.ecommerce-application .ecommerce-header-items .view-options .view-btn-option .btn.view-btn i
{
    font-size: 1.7rem;
}
.ecommerce-application .ecommerce-header-items .view-options .view-btn-option .btn.view-btn.active
{
    color: #7367f0 !important;
}
.ecommerce-application .ecommerce-header-items .view-options .view-btn-option .btn.view-btn.active:hover,
.ecommerce-application .ecommerce-header-items .view-options .view-btn-option .btn.view-btn.active:active
{
    color: #7367f0 !important;
}
.ecommerce-application .ecommerce-header-items .view-options .view-btn-option .btn.view-btn:hover,
.ecommerce-application .ecommerce-header-items .view-options .view-btn-option .btn.view-btn:active
{
    color: #626262;
}
.ecommerce-application .ecommerce-header-items .view-options .select2
{
    width: auto !important; 
    margin-right: 10px;
}
.ecommerce-application .ecommerce-header-items .view-options .select2 .select2-selection--single
{
    border: none !important;
    border-radius: 5px; 
    -webkit-box-shadow: 0 2px 8px 0 rgba(0, 0, 0, .14);
            box-shadow: 0 2px 8px 0 rgba(0, 0, 0, .14);
}
.ecommerce-application .ecommerce-header-items .result-toggler
{
    display: -webkit-box;
    display: -webkit-flex;
    display:    -moz-box;
    display: -ms-flexbox;
    display:         flex;

    -webkit-box-align: end;
    -webkit-align-items: flex-end;
       -moz-box-align: end;
    -ms-flex-align: end;
            align-items: flex-end;
}
.ecommerce-application .ecommerce-header-items .result-toggler .shop-sidebar-toggler
{
    font-size: 1.7rem;

    margin-left: -6px; 
    padding-left: 0;
}
.ecommerce-application .ecommerce-header-items .result-toggler .shop-sidebar-toggler:active,
.ecommerce-application .ecommerce-header-items .result-toggler .shop-sidebar-toggler:focus
{
    outline: 0;
}
.ecommerce-application .ecommerce-header-items .result-toggler .shop-sidebar-toggler .navbar-toggler-icon i
{
    vertical-align: middle; 

    color: #626262;
}
.ecommerce-application .ecommerce-header-items .result-toggler .search-results
{
    font-weight: 700;

    display: inline-block; 

    color: #626262;
}

.ecommerce-application .search-product
{
    font-size: 1.2rem;

    height: 48px;
    padding-left: 1rem; 

    border: none;
    -webkit-box-shadow: 0 2px 8px 0 rgba(0, 0, 0, .14);
            box-shadow: 0 2px 8px 0 rgba(0, 0, 0, .14);
}
.ecommerce-application .search-product ~ .form-control-position
{
    top: 10px;
    right: 10px;
}
.ecommerce-application .search-product ~ .form-control-position i
{
    font-size: 1.5rem;
}
.ecommerce-application .search-product::-webkit-input-placeholder
{
    font-size: .95rem;
}
.ecommerce-application .search-product:-moz-placeholder
{
    font-size: .95rem;
}
.ecommerce-application .search-product::-moz-placeholder
{
    font-size: .95rem;
}
.ecommerce-application .search-product:-ms-input-placeholder
{
    font-size: .95rem;
}
.ecommerce-application .search-product::placeholder
{
    font-size: .95rem;
}

.ecommerce-application .ecommerce-card:hover
{
    -webkit-transform: translateY(-5px);
       -moz-transform: translateY(-5px);
        -ms-transform: translateY(-5px);
         -o-transform: translateY(-5px);
            transform: translateY(-5px);

    -webkit-box-shadow: 0 4px 25px 0 rgba(0, 0, 0, .25);
            box-shadow: 0 4px 25px 0 rgba(0, 0, 0, .25);
}

.ecommerce-application .ecommerce-card .item-price
{
    font-weight: 700;
}

.ecommerce-application .ecommerce-card .item-rating i
{
    margin-left: .25rem;

    vertical-align: top;
}

.ecommerce-application .ecommerce-card .item-name
{
    font-weight: 600;

    color: #2c2c2c;
}

.ecommerce-application .ecommerce-card .item-description
{
    font-size: .875rem;
}

.ecommerce-application .ecommerce-card .wishlist,
.ecommerce-application .ecommerce-card .cart
{
    font-size: .875rem;
    font-weight: 600;

    padding: .8rem 1rem;

    cursor: pointer;
    text-transform: uppercase;
}
.ecommerce-application .ecommerce-card .wishlist i,
.ecommerce-application .ecommerce-card .cart i
{
    font-size: 1rem;

    margin-right: .25rem;
}

.ecommerce-application .ecommerce-card .wishlist
{
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none; 

    color: #2c2c2c;
    background-color: #f6f6f6;
}
.ecommerce-application .ecommerce-card .wishlist.added i
{
    color: #ea5455;
}

.ecommerce-application .ecommerce-card .cart
{
    color: #fff; 
    background-color: #7367f0;
}
.ecommerce-application .ecommerce-card .cart .view-in-cart
{
    color: #fff;
}

.ecommerce-application .grid-view,
.ecommerce-application .list-view
{
    margin-top: .7rem;
}

.ecommerce-application .grid-view
{
    display: grid;

    grid-template-columns: 1fr 1fr 1fr;
    -webkit-column-gap: 28px;
       -moz-column-gap: 28px;
            column-gap: 28px;
}
.ecommerce-application .grid-view .ecommerce-card
{
    overflow: hidden;
}
.ecommerce-application .grid-view .ecommerce-card .card-content .item-img
{
    min-height: 15.85rem; 
    padding-top: .5rem;
}
.ecommerce-application .grid-view .ecommerce-card .card-content .item-wrapper
{
    display: -webkit-box;
    display: -webkit-flex;
    display:    -moz-box;
    display: -ms-flexbox;
    display:         flex;

    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
       -moz-box-pack: justify;
    -ms-flex-pack: justify;
            justify-content: space-between;
}
.ecommerce-application .grid-view .ecommerce-card .card-content .item-wrapper .item-price
{
    position: relative;
    top: 5px;
}
.ecommerce-application .grid-view .ecommerce-card .card-content .item-wrapper .shipping
{
    display: none;
}
.ecommerce-application .grid-view .ecommerce-card .card-content .item-company
{
    display: none;
}
.ecommerce-application .grid-view .ecommerce-card .card-content .item-options
{
    display: -webkit-box;
    display: -webkit-flex;
    display:    -moz-box;
    display: -ms-flexbox;
    display:         flex;

    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
}
.ecommerce-application .grid-view .ecommerce-card .card-content .item-options .cart,
.ecommerce-application .grid-view .ecommerce-card .card-content .item-options .wishlist
{
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
       -moz-box-flex: 1;
    -ms-flex-positive: 1;
            flex-grow: 1;
}
.ecommerce-application .grid-view .ecommerce-card .card-content .item-options .wishlist
{
    padding: .8rem 2rem;
}
.ecommerce-application .grid-view .ecommerce-card .card-content .item-options .item-wrapper .item-rating
{
    display: none;
}
.ecommerce-application .grid-view .ecommerce-card .card-content .item-options .item-wrapper .item-price
{
    display: none;
}
.ecommerce-application .grid-view .ecommerce-card .card-content .item-name
{
    margin-top: 1rem;
}
.ecommerce-application .grid-view .ecommerce-card .card-content .item-name,
.ecommerce-application .grid-view .ecommerce-card .card-content .item-description
{
    overflow: hidden;

    white-space: nowrap;
    text-overflow: ellipsis;
}
.ecommerce-application .grid-view .ecommerce-card .card-body
{
    padding: 1rem;
    padding-bottom: 0;
}

.ecommerce-application .list-view
{
    display: grid;

    grid-template-columns: 1fr;
}
.ecommerce-application .list-view .ecommerce-card
{
    overflow: hidden;
}
.ecommerce-application .list-view .ecommerce-card .card-content
{
    display: grid;

    grid-template-columns: 1fr 2fr 1fr;
}
.ecommerce-application .list-view .ecommerce-card .card-content .item-img
{
    display: -webkit-box;
    display: -webkit-flex;
    display:    -moz-box;
    display: -ms-flexbox;
    display:         flex;

    height: 100%; 
    padding-right: 1rem;
    padding-left: 1rem;

    -webkit-align-self: center;
    -ms-flex-item-align: center;
            align-self: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
    -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
    -ms-flex-align: center;
            align-items: center;
}
.ecommerce-application .list-view .ecommerce-card .card-content .card-body
{
    padding: 1rem;
    padding-bottom: .5rem;

    border-right: 1px solid #dae1e7;
}
.ecommerce-application .list-view .ecommerce-card .card-content .card-body .item-rating
{
    display: none;
}
.ecommerce-application .list-view .ecommerce-card .card-content .card-body .item-price
{
    display: none;
}
.ecommerce-application .list-view .ecommerce-card .card-content .card-body .item-name
{
    font-size: .95rem; 

    margin-bottom: .25rem;
}
.ecommerce-application .list-view .ecommerce-card .card-content .card-body .item-company
{
    font-size: .875rem; 

    display: block;

    cursor: pointer;
}
.ecommerce-application .list-view .ecommerce-card .card-content .card-body .item-company .company-name
{
    color: #7367f0;
}
.ecommerce-application .list-view .ecommerce-card .card-content .item-options
{
    padding: 1rem;
}
.ecommerce-application .list-view .ecommerce-card .card-content .item-options .item-wrapper
{
    position: relative;
}
.ecommerce-application .list-view .ecommerce-card .card-content .item-options .item-wrapper .item-rating
{
    position: absolute;
    right: 0;
}
.ecommerce-application .list-view .ecommerce-card .card-content .item-options .item-wrapper .item-cost .item-price
{
    font-size: 16px; 

    position: relative;
    top: 55px;
}
.ecommerce-application .list-view .ecommerce-card .card-content .item-options .shipping
{
    position: relative;
    top: 50px;

    color: #b8c2cc;
}
.ecommerce-application .list-view .ecommerce-card .card-content .item-options .wishlist
{
    margin-top: 84px;
    margin-bottom: 10px;
}
.ecommerce-application .list-view .ecommerce-card .card-content .item-options .wishlist,
.ecommerce-application .list-view .ecommerce-card .card-content .item-options .cart
{
    border-radius: 6px;
}

.ecommerce-application .checkout-items .ecommerce-card .card-content .item-company
{
    margin-bottom: 0;
}

.ecommerce-application .checkout-items .ecommerce-card .card-content .stock-status-in
{
    font-size: .875rem; 
    font-weight: 400;

    color: #28c76f;
}

.ecommerce-application .checkout-items .ecommerce-card .card-content .stock-status-out
{
    font-size: .875rem; 
    font-weight: 400;

    color: #ea5455;
}

.ecommerce-application .checkout-items .ecommerce-card .card-content .item-quantity
{
    font-size: .875rem; 
    font-weight: 700;
}
.ecommerce-application .checkout-items .ecommerce-card .card-content .item-quantity .quantity-title
{
    margin-bottom: .5rem;
}
.ecommerce-application .checkout-items .ecommerce-card .card-content .item-quantity .quantity-counter-wrapper
{
    margin-left: -10px;
}

.ecommerce-application .checkout-items .ecommerce-card .card-content .delivery-date,
.ecommerce-application .checkout-items .ecommerce-card .card-content .offers
{
    font-size: 1rem; 
    font-weight: 500;

    margin-bottom: 0;
}

.ecommerce-application .checkout-items .ecommerce-card .card-content .delivery-date
{
    margin-top: 1.8rem; 

    color: #b8c2cc;
}

.ecommerce-application .checkout-items .ecommerce-card .card-content .offers
{
    color: #28c76f;
}

.ecommerce-application .checkout-options .options-title
{
    color: #b8c2cc;
}

.ecommerce-application .checkout-options .coupons
{
    display: -webkit-box;
    display: -webkit-flex;
    display:    -moz-box;
    display: -ms-flexbox;
    display:         flex;

    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
       -moz-box-pack: justify;
    -ms-flex-pack: justify;
            justify-content: space-between;
}
.ecommerce-application .checkout-options .coupons .coupons-title
{
    font-weight: 600;
}
.ecommerce-application .checkout-options .coupons .coupons-title p
{
    margin-bottom: .5rem;
}
.ecommerce-application .checkout-options .coupons .apply-coupon
{
    font-weight: 500;

    cursor: pointer; 

    color: #7367f0;
}
.ecommerce-application .checkout-options .coupons .apply-coupon p
{
    margin-bottom: .5rem;
}

.ecommerce-application .checkout-options .price-details
{
    font-weight: 600;

    margin-bottom: .75rem;
}

.ecommerce-application .checkout-options .detail
{
    display: -webkit-box;
    display: -webkit-flex;
    display:    -moz-box;
    display: -ms-flexbox;
    display:         flex;

    margin-bottom: .75rem; 

    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
       -moz-box-pack: justify;
    -ms-flex-pack: justify;
            justify-content: space-between;
}
.ecommerce-application .checkout-options .detail .detail-title
{
    color: #b8c2cc;
}
.ecommerce-application .checkout-options .detail .detail-title.detail-total
{
    font-weight: 600; 

    color: #626262;
}
.ecommerce-application .checkout-options .detail .detail-amt.discount-amt
{
    color: #28c76f;
}
.ecommerce-application .checkout-options .detail .detail-amt.emi-details
{
    cursor: pointer; 

    color: #7367f0;
}
.ecommerce-application .checkout-options .detail .detail-amt.total-amt
{
    font-weight: 600;
}

.ecommerce-application .payment-type .gift-card
{
    cursor: pointer;
}
.ecommerce-application .payment-type .gift-card i
{
    position: relative;
    top: 3px;
}

.ecommerce-application .wizard.checkout-tab-steps .steps ul li.error > a
{
    color: #7367f0;
}
.ecommerce-application .wizard.checkout-tab-steps .steps ul li.error > a .step
{
    border-color: #7367f0;
    background-color: #7367f0;
}

.ecommerce-application .wizard.checkout-tab-steps #checkout-address .form-group .error
{
    color: #ea5455;
}

@media (max-width: 1200px)
{
    .ecommerce-application .app-content
    {
        overflow: scroll;
    }
}

@media (max-width: 991.98px)
{
    .ecommerce-application .sidebar-left .sidebar
    {
        position: absolute;
        top: 14rem;
    }
    .ecommerce-application .sidebar-left .sidebar .card
    {
        padding-bottom: 100vh; 

        border-radius: 0;
    }
    .ecommerce-application .sidebar-left .sidebar .card .card-body
    {
        padding-bottom: 100%;
    }
    .ecommerce-application .sidebar-left .sidebar .sidebar-shop
    {
        position: absolute; 

        -webkit-transition: all .25s ease;
           -moz-transition: all .25s ease;
             -o-transition: all .25s ease;
                transition: all .25s ease;
        -webkit-transform: translateX(-112%);
           -moz-transform: translateX(-112%);
            -ms-transform: translateX(-112%);
             -o-transform: translateX(-112%);
                transform: translateX(-112%);
    }
    .ecommerce-application .sidebar-left .sidebar .sidebar-shop.show
    {
        -webkit-transition: all .25s ease;
           -moz-transition: all .25s ease;
             -o-transition: all .25s ease;
                transition: all .25s ease;
        -webkit-transform: translateX(0);
           -moz-transform: translateX(0);
            -ms-transform: translateX(0);
             -o-transform: translateX(0);
                transform: translateX(0);
    }
    .ecommerce-application .ecommerce-header-items .result-toggler .shop-sidebar-toggler
    {
        position: relative;
        top: 1.2rem;
    }
    .ecommerce-application .grid-view
    {
        grid-template-columns: 1fr 1fr;
    }
}

@media (max-width: 991.98px)
{
    .ecommerce-application .ecommerce-header-items .result-toggler .search-results
    {
        display: none;
    }
    .ecommerce-application .ecommerce-header-items .result-toggler .shop-sidebar-toggler
    {
        top: .5rem;
    }
    .ecommerce-application .ecommerce-header-items .sidebar-shop
    {
        z-index: 5; 

        -webkit-transition: all .2s ease;
           -moz-transition: all .2s ease;
             -o-transition: all .2s ease;
                transition: all .2s ease;
        -webkit-transform: translateX(-123%);
           -moz-transform: translateX(-123%);
            -ms-transform: translateX(-123%);
             -o-transform: translateX(-123%);
                transform: translateX(-123%);
    }
    .ecommerce-application .ecommerce-header-items .sidebar-shop.show
    {
        position: absolute;

        -webkit-transition: all .2s ease;
           -moz-transition: all .2s ease;
             -o-transition: all .2s ease;
                transition: all .2s ease; 
        -webkit-transform: translateX(0);
           -moz-transform: translateX(0);
            -ms-transform: translateX(0);
             -o-transform: translateX(0);
                transform: translateX(0);
    }
    .ecommerce-application .list-view .ecommerce-card .card-content
    {
        grid-template-columns: 1fr;
    }
    .ecommerce-application .list-view .ecommerce-card .card-content .item-img
    {
        padding-top: 2rem;
        padding-bottom: 2rem;
    }
    .ecommerce-application .list-view .ecommerce-card .card-content .card-body
    {
        border: none;
    }
}

@media (max-width: 576px)
{
    .ecommerce-application .sidebar-shop
    {
        top: 0;
    }
    .ecommerce-application .grid-view
    {
        grid-template-columns: 1fr;
    }
}

@media (min-width: 992px)
{
    .ecommerce-application .ecommerce-header-items .shop-sidebar-toggler
    {
        display: none;
    }
    .ecommerce-application .wishlist-items.grid-view
    {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
    .ecommerce-application .product-checkout.list-view
    {
        grid-template-columns: 2fr 1fr;
        -webkit-column-gap: 20px;
           -moz-column-gap: 20px;
                column-gap: 20px;
    }
}

@media (max-width: 360px)
{
    .ecommerce-application .sidebar-shop
    {
        top: 1.5rem;
    }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)
{
    .ecommerce-application .grid-view
    {
        display: block;
    }
    .ecommerce-application .grid-view .ecommerce-card
    {
        float: left;

        width: 31%;
        margin-right: 1%; 
        margin-left: 1%;
    }
    .ecommerce-application .list-view
    {
        display: block;
    }
    .ecommerce-application .list-view .ecommerce-card .card-content
    {
        display: -webkit-box;
        display: -webkit-flex;
        display:    -moz-box;
        display: -ms-flexbox;
        display:         flex;
    }
    .ecommerce-application .list-view .ecommerce-card .card-content .item-options .wishlist,
    .ecommerce-application .list-view .ecommerce-card .card-content .item-options .cart
    {
        display: -webkit-box;
        display: -webkit-flex;
        display:    -moz-box;
        display: -ms-flexbox;
        display:         flex;

        padding: .8rem 3rem;

        -webkit-box-pack: center;
        -webkit-justify-content: center;
           -moz-box-pack: center;
        -ms-flex-pack: center;
                justify-content: center;
    }
    .ecommerce-application #ecommerce-pagination .row
    {
        clear: both;
    }
}
